import { AxiosResponse } from 'axios';
import validate from 'validate.js';
import { StatusCodes } from 'http-status-codes';
import {
  AddRoleToUserRequest,
  AddRoleToUserResponse,
  EnableDisableRequest,
  EnabledUser,
  ErrorResponse,
  FetchRolesResponse,
  FetchRolesV2Response,
  IdentityV2Response,
  InviteUsersRequest,
  InviteUsersRequestV2,
  InviteUsersResponse,
  InviteUsersResponseV2,
  RemoveRoleToUserRequest,
  ResendInviteRequest,
  Role,
  UpdateUserRequest,
  UpdateUserResponse,
  UpdatePasswordV2Request,
  UpdatePasswordV2Response,
  UserListRequest,
  UserListResponse,
  UpdateUserRolesRequest,
  TenantOrganizationResponse,
  FetchLabelsResponse,
  UpdateUserRoleLabelResponse,
  UpdateUserRolesRequestWithLabels,
} from '../../../@types';
import api from '../../services/api';
import {
  enableDisableUserService,
  inviteUsersService,
  resendInviteService,
  inviteUsersServiceV2,
  removeUserServiceV2,
  fetchRoleListService,
  fetchUserListServiceV2,
  fetchUserListServiceV3,
  fetchRolesServiceV2,
  addRoleToUserServiceV2,
  removeRoleFromUserServiceV2,
  resendInviteV2Service,
  updateUserServiceV2,
  updatePasswordServiceV2,
  updateUserRolesServiceV2,
  fetchOrgDetailByTenantId,
  fetchLabelsService,
  updateUserLabelService,
  updateUserRolesServiceV2WithLabels,
  updateUserRolesService,
  updateRolesForMultipleUsersService,
  addRolesForMultipleUsersService,
  removeBulkUserServiceV2,
} from './services';
import {
  validateRemoveUserV2,
  validateAddRoleToUserV2,
  validateEnableDisableUser,
  validateResendInvite,
  validateRoleList,
  validateRoleListV2,
  validateRemoveRoleFromUserV2,
  validateUserListV2,
  validateResendInviteV2,
  validateUpdateUserV2,
  validateUpdatePasswordV2,
} from './validators';

export { fetchUsers } from './services';

export async function fetchRoles(): Promise<
  FetchRolesResponse | ErrorResponse
> {
  try {
    let roleList = await fetchRoleListService();
    validateRoleList(roleList);
    roleList = roleList.map((role: Role) => {
      return {
        ...role,
        id: role._id,
      };
    });
    return {
      roles: roleList,
    };
  } catch (err) {
    return {
      error: {
        message: err.message,
        statusCode: err.status ?? StatusCodes.INTERNAL_SERVER_ERROR,
      },
    };
  }
}

export function validateUserList(apiResponse: UserListResponse): boolean {
  const baseError = validate(apiResponse, {
    'query.limit': { type: 'number', presence: true },
    'query.count': { type: 'number', presence: true },
    'query.offset': { type: 'number', presence: true },
    'query.sortBy': { type: 'string', presence: { allowEmpty: false } },
    'query.sortOrder': { type: 'string', presence: { allowEmpty: false } },
    'users': { type: 'array', presence: { allowEmpty: true } },
  });

  if (baseError) {
    throw baseError;
  }
  return true;
}

export async function fetchUserListService(
  request: UserListRequest
): Promise<UserListResponse> {
  const response: AxiosResponse = await api.get('/api-identity/user', {
    params: {
      offset: request.offset,
      limit: request.limit,
    },
  });
  return {
    query: response.data.query,
    users: response.data.users.map((user) => ({
      ...user,
      name: [user.name.first, user.name.last].join(' '),
    })),
  };
}

export async function fetchUsersList({
  limit,
  offset,
  pageSize,
}: UserListRequest): Promise<UserListResponse> {
  try {
    const response: UserListResponse = await fetchUserListService({
      limit,
      offset,
      pageSize,
    });
    validateUserList(response);
    return response;
  } catch (error) {
    return {
      users: [error],
    };
  }
}

export async function fetchUsersListV2({
  limit,
  offset,
  pageSize,
}: UserListRequest): Promise<UserListResponse> {
  try {
    const response: UserListResponse = await fetchUserListServiceV2({
      limit,
      offset,
      pageSize,
    });

    validateUserListV2(response);
    const { users } = response;
    const usersData = [];
    Object.values(users).forEach((value) => {
      Object.keys(value).forEach((key) => {
        usersData.push({ ...value[key], userId: key });
      });
    });
    return {
      users: usersData,
      query: response.query,
    };
  } catch (error) {
    return {
      users: [error],
    };
  }
}

export async function fetchUsersListV3({
  limit,
  offset,
  pageSize,
  data,
}: UserListRequest): Promise<UserListResponse> {
  try {
    const response: UserListResponse = await fetchUserListServiceV3({
      limit,
      offset,
      pageSize,
      data,
    });

    validateUserListV2(response);
    const { users } = response;
    const usersData = [];
    Object.values(users).forEach((value) => {
      Object.keys(value).forEach((key) => {
        usersData.push({ ...value[key], userId: key });
      });
    });
    return {
      users: usersData,
      query: response.query,
    };
  } catch (error) {
    throw error;
  }
}

/**
 * @deprecated Use updateUserRolesV2 instead
 */
export async function addRoleToUserV2(
  request: AddRoleToUserRequest
): Promise<AddRoleToUserResponse | ErrorResponse> {
  try {
    const response: AddRoleToUserResponse = await addRoleToUserServiceV2(
      request
    );
    validateAddRoleToUserV2(response);
    return response;
  } catch (error) {
    return {
      error: {
        statusCode: error.response?.status,
        message: error.message,
      },
    };
  }
}

/**
 * @deprecated Use updateUserRolesV2 instead
 */
export async function removeRoleFromUserV2(
  request: RemoveRoleToUserRequest
): Promise<AddRoleToUserResponse | ErrorResponse> {
  try {
    const response: AddRoleToUserResponse = await removeRoleFromUserServiceV2(
      request
    );
    validateRemoveRoleFromUserV2(response);
    return response;
  } catch (error) {
    return {
      error: {
        statusCode: error.response?.status,
        message: error.message,
      },
    };
  }
}

export async function updateUserRolesV2(
  request: UpdateUserRolesRequest
): Promise<AxiosResponse | ErrorResponse> {
  try {
    return await updateUserRolesServiceV2(request);
  } catch (error) {
    return {
      error: {
        statusCode: error.response?.status,
        message: error.message,
      },
    };
  }
}

export async function updateUserRolesV2WithLabels(
  request: UpdateUserRolesRequestWithLabels
): Promise<AxiosResponse | ErrorResponse> {
  try {
    return await updateUserRolesServiceV2WithLabels(request);
  } catch (error) {
    return {
      error: {
        statusCode: error.response?.status,
        message: error.message,
      },
    };
  }
}
export async function inviteUsers(
  request: InviteUsersRequest
): Promise<InviteUsersResponse | ErrorResponse> {
  try {
    const response: InviteUsersResponse = await inviteUsersService(request);
    return response;
  } catch (error) {
    return { error: { message: error.message, statusCode: error.status } };
  }
}

export async function inviteUsersV2(
  request: InviteUsersRequestV2,
  enableValidation = true
): Promise<InviteUsersResponseV2 | ErrorResponse> {
  try {
    const response: InviteUsersResponseV2 = await inviteUsersServiceV2(
      request,
      enableValidation
    );
    return response;
  } catch (error) {
    return { error: { message: error.message, statusCode: error.status } };
  }
}

export async function resendInvite(
  request: ResendInviteRequest
): Promise<boolean | ErrorResponse> {
  try {
    const response: boolean = await resendInviteService(request);
    validateResendInvite(response);
    return response;
  } catch (error) {
    return {
      error: {
        message: error.message,
        statusCode: error.status,
      },
    };
  }
}

export async function resendInviteV2(
  userId: string
): Promise<IdentityV2Response | ErrorResponse> {
  try {
    const response: IdentityV2Response = await resendInviteV2Service(userId);
    validateResendInviteV2(response);
    return response;
  } catch (error) {
    return {
      error: {
        message: error.message,
        statusCode: error.status,
      },
    };
  }
}

export async function enableDisableUser(
  request: EnableDisableRequest
): Promise<EnabledUser | ErrorResponse> {
  try {
    const response: EnabledUser = await enableDisableUserService(request);
    validateEnableDisableUser(response);
    return response;
  } catch (error) {
    return {
      error: {
        message: error.message,
        statusCode: error.status,
      },
    };
  }
}

export async function removeUserV2(
  userId: string
): Promise<IdentityV2Response | ErrorResponse> {
  try {
    const response: IdentityV2Response = await removeUserServiceV2(userId);
    validateRemoveUserV2(response);
    return response;
  } catch (error) {
    return {
      error: {
        message: error.message,
        statusCode: error.status,
      },
    };
  }
}

export async function fetchRolesV2(): Promise<
  FetchRolesV2Response | ErrorResponse
> {
  try {
    const roleList = await fetchRolesServiceV2();
    validateRoleListV2(roleList);
    return {
      roles: roleList,
    };
  } catch (err) {
    return {
      error: {
        message: err.message,
        statusCode: err.status ?? StatusCodes.INTERNAL_SERVER_ERROR,
      },
    };
  }
}

export async function updatePasswordV2(
  request: UpdatePasswordV2Request
): Promise<UpdatePasswordV2Response | ErrorResponse> {
  try {
    const updatedUser: UpdatePasswordV2Response = await updatePasswordServiceV2(
      request
    );
    validateUpdatePasswordV2(updatedUser);
    return updatedUser;
  } catch (err) {
    return {
      error: {
        message: err?.response?.data?.message
          ? err.response.data.message
          : err.message,
        statusCode: err.status ?? StatusCodes.INTERNAL_SERVER_ERROR,
      },
    };
  }
}

export async function updateUserV2(
  user: UpdateUserRequest
): Promise<UpdateUserResponse | ErrorResponse> {
  try {
    const updatedUser: UpdateUserResponse = await updateUserServiceV2(user);
    validateUpdateUserV2(updatedUser);
    sessionStorage.setItem('firstName', updatedUser.firstName);
    sessionStorage.setItem('lastName', updatedUser.lastName);
    sessionStorage.setItem('email', updatedUser.email);
    window.dispatchEvent(
      new CustomEvent('onUserUpdate', { detail: updatedUser })
    );
    return updatedUser;
  } catch (err) {
    return {
      error: {
        message: err.message,
        statusCode: err.status ?? StatusCodes.INTERNAL_SERVER_ERROR,
      },
    };
  }
}

export async function getOrgDetailByTenantId(
  tenantId: string
): Promise<TenantOrganizationResponse | ErrorResponse> {
  try {
    const tenantOrgResponse: TenantOrganizationResponse = await fetchOrgDetailByTenantId(
      tenantId
    );
    return tenantOrgResponse;
  } catch (err) {
    return {
      error: {
        message: err.message,
        statusCode: err.status ?? StatusCodes.INTERNAL_SERVER_ERROR,
      },
    };
  }
}

export async function fetchLabels(
  request
): Promise<FetchLabelsResponse | ErrorResponse> {
  try {
    const labelsList = await fetchLabelsService(request);
    return {
      labels: labelsList,
    };
  } catch (err) {
    return {
      error: {
        message: err.message,
        statusCode: err.status ?? StatusCodes.INTERNAL_SERVER_ERROR,
      },
    };
  }
}

export async function updateLabelsForUser(
  request
): Promise<UpdateUserRoleLabelResponse | ErrorResponse> {
  try {
    const updatedLabels = await updateUserLabelService(request);
    return updatedLabels;
  } catch (err) {
    return {
      error: {
        message: err.message,
        statusCode: err.status ?? StatusCodes.INTERNAL_SERVER_ERROR,
      },
    };
  }
}

export async function updateRolesForUser(
  request
): Promise<UpdateUserRoleLabelResponse | ErrorResponse> {
  try {
    const updatedLabels = await updateUserRolesService(request);
    return updatedLabels;
  } catch (err) {
    return {
      error: {
        message: err.message,
        statusCode: err.status ?? StatusCodes.INTERNAL_SERVER_ERROR,
      },
    };
  }
}
export async function updateRolesForMultipleUsers(
  request
): Promise<UpdateUserRoleLabelResponse | ErrorResponse> {
  try {
    const updatedUsers = await updateRolesForMultipleUsersService(request);
    return updatedUsers;
  } catch (err) {
    return {
      error: {
        message: err.message,
        statusCode: err.status ?? StatusCodes.INTERNAL_SERVER_ERROR,
      },
    };
  }
}

export async function addRolesForMultipleUsers(
  request
): Promise<UpdateUserRoleLabelResponse | ErrorResponse> {
  try {
    const updatedUsers = await addRolesForMultipleUsersService(request);
    return updatedUsers;
  } catch (err) {
    return {
      error: {
        message: err.message,
        statusCode: err.status ?? StatusCodes.INTERNAL_SERVER_ERROR,
      },
    };
  }
}

export async function removeBulkUserV2(
  request
): Promise<UpdateUserResponse | ErrorResponse> {
  try {
    const updatedUsers = await removeBulkUserServiceV2(request);
    return updatedUsers;
  } catch (err) {
    return {
      error: {
        message: err.message,
        statusCode: err.status ?? StatusCodes.INTERNAL_SERVER_ERROR,
      },
    };
  }
}
