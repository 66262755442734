import commerceGPTAPI from 'src/services/commerceGPTAPI';
import { CommerceGPTResponse, CommerceGPTDataObject, UserPrompt } from '../../../@types';

export const sendUserPrompt = async (input: UserPrompt): Promise<CommerceGPTResponse> => {
  const response = await commerceGPTAPI.post('/actions/send', input);
  return response;
};

export const getLeadMessages =
  async (
    userId: string,
    conversationGroup: CommerceGPTConversationGroupType,
  ): Promise<Array<CommerceGPTDataObject>> => {
    const response = await commerceGPTAPI.get<Array<CommerceGPTDataObject>>('/lead-messages', {
      params: {
        userId,
        conversationGroup,
      },
    });
    return response?.data;
  };

export const getSuggestedActions =
  async (): Promise<Array<CommerceGPTDataObject>> => {
    const response = await commerceGPTAPI.get<Array<CommerceGPTDataObject>>('/suggested-actions');
    return response?.data;
  };

export const getInsights = async (): Promise<Array<CommerceGPTDataObject>> => {
  const response = await commerceGPTAPI.get<Array<CommerceGPTDataObject>>('/insights');
  return response?.data;
};

export const getSuggestedQuestions =
  async (): Promise<Array<CommerceGPTDataObject>> => {
    const response = await commerceGPTAPI.get<Array<CommerceGPTDataObject>>('/suggested-questions');
    return response?.data;
  };

export const getMessages =
  async (userId: string, conversationId: string): Promise<Array<CommerceGPTDataObject>> => {
    const response = await commerceGPTAPI.get<Array<CommerceGPTDataObject>>('/messages', {
      params: {
        userId,
        conversationId,
      },
    });
    return response?.data;
  };

export enum CommerceGPTMessageType {
  TEXT = 'TEXT',
  RECOMMENDATION = 'RECOMMENDATION',
  INSIGHT = 'INSIGHT',
  ACTION = 'ACTION'
}

export enum CommerceGPTConversationGroupType {
  HOME = 'Home',
  PRODUCTS = 'Products',
  ORDERS = 'Orders',
  INVENTORY = 'Inventory'
}
