import { ValidationError } from 'joi';
import {
  CurrenciesRequest,
  PriceListRequest,
  PriceListResponse,
  SKUWarningRequest,
  CreateSkuPriceRequest,
  CreateSkuPriceResponse,
  CreatePriceListRequest,
  CreatePriceListResponse,
  SKUPriceListResponse,
  ErrorResponse,
  DeletedPriceListResponse,
  AddSkuRequest,
  UpdatePriceListRequest,
  GetBySkuResponse,
  UpdateSkuPriceResponse,
  UpdateSkuPriceRequest,
  GetBySku,
  PriceListSearchResponse,
  PriceListSearchRequest,
  AddSku,
  GetPriceBySkuRequest,
  GetPriceBySkuResponse,
  CreatePriceRangeRequest,
  PriceList,
  CreatePriceRangeResponse,
  GetAddonsRequest,
  GetAddonsResponse,
  UpdatePriceRangeResponse,
  CreateUpdatePriceRangeRequestOffer,
} from '../../../@types';
import currenciesList from './mocked/currenciesList';
import mockedSkuWarningItems from './mocked/mockedSkuWarning';
import mockedPrices from './mocked/mockedPrices';
import api from '../../services/api';
import {
  validateCurrencyCodes,
  validateAddSkuResponse,
  validateDeletePriceListResponse,
  validateFetchAllPriceListsResponse,
  validatePriceListSearch,
  validateSkuPriceResponse,
  validateGetPriceBySkuResponse,
  validateGetAddonsResponse,
  validateCreatePriceResponse,
  validateCreatePriceListResponse,
  validateUpdatePriceResponse,
} from './validators';
import {
  addSKUService,
  createSkuPriceService,
  createPriceListService,
  deletePriceListService,
  fetchAllPriceListsService,
  searchPriceListService,
  updatePriceListService,
  fetchCurrencyCodesService,
  createPriceRangeService,
  getPriceBySkuService,
  getAddonsService,
  updatePriceRangeService,
} from './services';
import getValidationErrors from '../../lib';

export async function fetchAllPriceLists(params?: PriceListRequest):
  Promise<PriceListResponse | ErrorResponse> {
  try {
    const { data, query } = await fetchAllPriceListsService(params);
    await validateFetchAllPriceListsResponse(data);
    return { data, query };
  } catch (error) {
    let { message } = error;
    if (error instanceof ValidationError) {
      message = getValidationErrors(error);
    }
    return { error: { message, statusCode: error.status } };
  }
}

export async function deletePriceList(id: string):
  Promise<DeletedPriceListResponse | ErrorResponse> {
  try {
    const data = await deletePriceListService(id);
    validateDeletePriceListResponse(data);
    return { data };
  } catch (error) {
    return {
      error: {
        statusCode: error.response?.status,
        message: error.message,
      },
    };
  }
}

export async function fetchSKUWarningList(priceListId?: number): Promise<SKUWarningRequest> {
  if (priceListId) {
    const filtered = mockedSkuWarningItems.filter(item => item.priceListId === priceListId);
    return {
      data: filtered,
    };
  }
  return { data: mockedSkuWarningItems };
}

export async function fetchSKUPriceList(
  offset: number,
  limit: number,
  priceListId?: number,
  sort?: {
    attribute: string;
    order: string;
  },
): Promise<SKUPriceListResponse> {
  let result = mockedPrices;
  if (priceListId) {
    result = mockedPrices.filter(
      item => item.priceListId === priceListId,
    );
  }
  /* TODO: remove sorting from FE controller. Sorting should happen on the backend, otherwise we're
    doing a partial sort on the subset of data returned and are breaking the user experience.
  */
  if (sort) {
    result.sort((a, b) => {
      let value = 0;
      if (a[sort.attribute] < b[sort.attribute]) {
        value = -1;
      }
      if (a[sort.attribute] > b[sort.attribute]) {
        value = 1;
      }
      return value * (sort.order === 'ASC' ? 1 : -1);
    });
  }
  return {
    data: result.slice(offset, offset + limit),
    total: result.length,
  };
}

export async function fetchCurrencies(): Promise<CurrenciesRequest[]> {
  return currenciesList;
}

export async function createSkuPrice(createSkuPriceRequest: CreateSkuPriceRequest):
  Promise<CreateSkuPriceResponse> {
  try {
    const response = await createSkuPriceService(createSkuPriceRequest);
    validateSkuPriceResponse(response.data);
    return {
      data: response.data,
    };
  } catch (error) {
    return {
      data: [error],
    };
  }
}

export async function updateSkuPrice(updateSkuPriceRequest: UpdateSkuPriceRequest):
  Promise<UpdateSkuPriceResponse> {
  try {
    const response = await api.put<UpdateSkuPriceResponse>('/api-offers/price', {
      updateSkuPriceRequest,
    });
    const { data } = response;
    return data;
  } catch (error) {
    return error;
  }
}

export async function addSku(request: AddSkuRequest): Promise<AddSku | ErrorResponse> {
  try {
    const data = await addSKUService(request);
    validateAddSkuResponse(data);
    delete data._id;
    return data;
  } catch (error) {
    return { error: { message: error.message, statusCode: error.status } };
  }
}

export async function getBySku(sku: GetBySku): Promise<GetBySkuResponse> {
  try {
    const response = await api.post<GetBySkuResponse>('/api-offers/price', sku);
    const { data } = response;
    return data;
  } catch (error) {
    return error;
  }
}

export async function createPriceList(request: CreatePriceListRequest):
  Promise<CreatePriceListResponse | ErrorResponse> {
  try {
    const response = await createPriceListService(request);
    validateCreatePriceListResponse(response);
    return response;
  } catch (error) {
    return {
      error: {
        statusCode: error.response?.status,
        message: error.message,
      },
    };
  }
}

export async function updatePriceList(request: UpdatePriceListRequest, priceListId: number):
  Promise<PriceList | ErrorResponse> {
  try {
    const data = await updatePriceListService(request, priceListId);
    return data;
  } catch (error) {
    return {
      error: {
        statusCode: error.status,
        message: error.message,
      },
    };
  }
}

export async function searchPriceList(
  request: PriceListSearchRequest,
): Promise<PriceListSearchResponse | ErrorResponse> {
  try {
    const data: PriceListSearchResponse = await searchPriceListService(request);
    validatePriceListSearch(data);
    return data;
  } catch (error) {
    return {
      error: {
        statusCode: error.status,
        message: error.message,
      },
    };
  }
}

export async function getPriceBySku(request: GetPriceBySkuRequest): Promise<GetPriceBySkuResponse
    | ErrorResponse> {
  try {
    const response: GetPriceBySkuResponse = await getPriceBySkuService(request);
    const validatedData = validateGetPriceBySkuResponse(response);

    return validatedData;
  } catch (error) {
    return { error: { statusCode: error.status, message: error.message } };
  }
}

export async function createPriceRange(request: CreatePriceRangeRequest):
  Promise<CreatePriceRangeResponse | ErrorResponse> {
  try {
    const response = await createPriceRangeService(request);
    const validatedData = validateCreatePriceResponse(response);
    return validatedData;
  } catch (error) {
    return {
      error: {
        statusCode: error.response?.status,
        message: error.message,
      },
    };
  }
}

export async function updatePriceRange(
  request: CreateUpdatePriceRangeRequestOffer, priceListId: number, itemSku: string,
):
  Promise<UpdatePriceRangeResponse | ErrorResponse> {
  try {
    const response = await updatePriceRangeService(request, priceListId, itemSku);
    const validatedData = validateUpdatePriceResponse(response);
    return validatedData;
  } catch (error) {
    return {
      error: {
        statusCode: error.response?.status,
        message: error.message,
      },
    };
  }
}

interface CurrencyCodesResponse {
  codes: Array<any>;
}

export async function fetchCurrencyCodes(): Promise<CurrencyCodesResponse | ErrorResponse> {
  try {
    const data = await fetchCurrencyCodesService();
    validateCurrencyCodes(data);
    return data;
  } catch (error) {
    return {
      error: {
        statusCode: error.status,
        message: error.message,
      },
    };
  }
}

export async function getAddons(
  request: GetAddonsRequest,
): Promise<GetAddonsResponse | ErrorResponse> {
  try {
    const data = await getAddonsService(request);
    const validatedData = validateGetAddonsResponse(data);
    return validatedData;
  } catch (error) {
    return {
      error: {
        statusCode: error.response?.status,
        message: error.message,
      },
    };
  }
}
