import axios from 'axios';
import { SESSION_STORAGE_KEYS, LOCAL_STORAGE_KEYS } from '../lib/constants';

const apiDx = axios.create({
  responseType: 'json',
});

apiDx.interceptors.request.use((config) => {
  const stageName = new URL(window.location.origin).hostname?.split('.')[0];
  config.baseURL = `//${stageName}.tenants.fabric.inc/v1`;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  config.headers.Authorization = sessionStorage.getItem(SESSION_STORAGE_KEYS.ACCESS_TOKEN);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (!config.headers['x-fabric-tenant-id']) {
    const tenantId = localStorage.getItem(LOCAL_STORAGE_KEYS.IS_V2_LOGIN) === 'true' ? sessionStorage.getItem(SESSION_STORAGE_KEYS.ACCOUNT_ID) : sessionStorage.getItem(SESSION_STORAGE_KEYS.ACCOUNT); // account is used in iDv1 and accountID is used in iDv2
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    config.headers['x-fabric-tenant-id'] = tenantId;
  }
  return config;
});

export default apiDx;
